<template>
  <div>
    <filter-view @submit="loadEchart">
      <purchase-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <!-- 分析图表 -->
      <div class="padding-10">
        <div v-if="storeChart && storeChart.length">
          <div class="c h">
            <div>显示同比</div>
            <div class="flex">
              <el-switch v-model="options.yearOnYearShow" @change="draw" />
            </div>
          </div>
          <div>显示数据</div>
          <div class="c h">
            <div class="flex"></div>
            <el-checkbox-group v-model="options.fields" size="mini" @change="draw">
              <el-checkbox-button v-for="f in fieldDefines" :label="f.field" :key="f.field">{{ f.name }}</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div ref="chart"></div>
        </div>
        <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <div class="padding-10" v-if="storeChart && storeChart.length>0">
        <div>数据列表</div>
        <div v-for="(o,i) in storeChart" :key="i" class="text item padding-10-0">
          <el-card class="box-card" shadow="never">
            <span v-if="query.dateGroup ==='year'">{{o.year}}</span>
            <span v-if="query.dateGroup ==='month'">{{o.year}}/{{o.month}}</span>
            <span v-if="query.dateGroup ==='day'">{{o.year}}/{{o.month}}/{{o.day}}</span>
          </el-card>
          <div class="c h">
            <el-card class="box-card ta-c" shadow="never" style="width:50%">
              <div>销售金额</div>
              <div style="color:red;">{{$price(o.realSumMoney)}}</div>
              <div>优惠前金额</div>
              <div style="color:red;">{{$price(o.sumMoney)}}</div>
              <div>同比金额</div>
              <div style="color:red;">{{$price(o.compareRealSumMoney)}}</div>
              <div>
                <span>同比增长率</span>
                <span v-if="o.realSumMoneyRatio" style="color:red;">{{ Math.round(o.realSumMoneyRatio * 10000) / 100}}%</span>
                <span style="color:red;" v-else>--</span>
              </div>
            </el-card>
            <el-card class="box-card" shadow="never" style="width:50%;">
              <div class="fs-small">发货金额</div>
              <div style="color:red;">{{$price(o.realSendMoney)}}</div>
              <div>优惠前金额</div>
              <div style="color:red;">{{$price(o.sendMoney)}}</div>
              <div>同比金额</div>
              <div style="color:red;">{{$price(o.compareRealSendMoney)}}</div>
              <div>
                <span>同比增长率</span>
                <span v-if="o.realSendMoneyRatio" style="color:red;">{{ Math.round(o.realSendMoneyRatio * 10000) / 100}}%</span>
                <span style="color:red;" v-else>--</span>
              </div>
            </el-card>
          </div>
          <div class="c h">
            <el-card class="box-card ta-c" shadow="never" style="width:50%;">
              <div>
                <span>销售数量</span>
                <span style="color:red;">{{o.saleNumber||"--"}}</span>
              </div>

              <div>
                <span>同比数量</span>
                <span style="color:red;">{{o.compareSaleNumber||"--"}}</span>
              </div>

              <div>
                <span>同比增长率</span>
                <span v-if="o.saleNumberRatio" style="color:red;">{{ Math.round(o.saleNumberRatio * 10000) / 100}}%</span>
                <span style="color:red;" v-else>--</span>
              </div>
            </el-card>
            <el-card class="box-card" shadow="never" style="width:50%;">
              <div>
                <span>发货数量</span>
                <span style="color:red;">{{o.sendNumber||"--"}}</span>
              </div>
              <div>
                <span>同比数量</span>
                <span style="color:red;">{{o.compareSendNumber||"--"}}</span>
              </div>
              <div>
                <span>同比增长率</span>
                <span v-if="o.sendNumberRatio" style="color:red;">{{ Math.round(o.sendNumberRatio * 10000) / 100}}%</span>
                <span style="color:red;" v-else>--</span>
              </div>
            </el-card>
          </div>
        </div>
        <div class="ta-c">
          <el-pagination :total="totalElements" :current-page="page" :page-size="size" small layout="prev, pager, next" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import purchaseFilter from "../assembly/filter/purchase";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    purchaseFilter,
  },
  data() {
    let now = new Date();
    return {
      page: 1,
      size: 6,
      totalElements: null,
      storeChart: [], //图表数据
      chart: null,
      showOpt: {
        showShop: false, //显示直营门店
        showSeller: true, //显示供应商
        showDimension: true, //分析纬度
        showRangGoods: true, //商品范围
        showCommodity: true, //指定商品
        showCompareyear: true, //同比年度
        showYearonyear: false, //同比
        showMonth: false,
        showRank: false,
        showRankGist: false,
        showLevel: false, //汇总级别
        showCategory: false, //对比类型
        showReported: false, //仅显示已上报
      },
      query: {
        addressType: "area",
        StandardAreaGroup: null,
        dateGroup: "day",
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        brandId: null,
        seriesId: null,
        categoryId: null,
        goods: null,
        compareYear: now.getFullYear() - 1,
        sellerId: null,
        page: 0,
        size: 10000,
      },
      fieldDefines: [
        {
          field: "saleNumber_checkSaleNumber",
          name: "销售数量",
        },
        {
          field: "realSumMoney_checkRealSumMoney",
          name: "销售金额",
        },
        {
          field: "sendNumber_checkSendNumber",
          name: "出货数量",
        },
        {
          field: "sendMoney_checkSendMoney",
          name: "出货金额",
        },
      ],
      fields: {
        saleNumber_checkSaleNumber: {
          label: "销售数量",
          key: "saleNums",
          ratio: 1,
          yIndex: 1,
        },
        realSumMoney_checkRealSumMoney: {
          label: "销售金额",
          key: "saleMoney",
          ratio: 0.000001,
          yIndex: 0,
        },
        sendNumber_checkSendNumber: {
          label: "出货数量",
          key: "sendNums",
          ratio: 1,
          yIndex: 1,
        },
        sendMoney_checkSendMoney: {
          label: "出货金额",
          key: "sendMoney",
          ratio: 0.000001,
          yIndex: 0,
        },
      },
      options: {
        yearOnYearShow: false,
        fields: ["realSumMoney_checkRealSumMoney"],
        YOYFields: ["realSumMoney_checkRealSumMoney"],
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.loadEchart();
    },
    makeParams() {
      let params = JSON.parse(JSON.stringify(this.query));
      params.begDate = new Date(params.begDate);
      params.endDate = new Date(params.endDate);
      if (params.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          params.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          params.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          params.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          params.StandardAreaGroup = "district";
        }
        delete params.treeNodeType;
      } else {
        params.treeNodeType = this.query.treeNodeType;
        delete params.StandardAreaGroup;
      }
      if (params.dateGroup === "year") {
        params.begDate = new Date(params.begDate.getFullYear(), 0, 1).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear() + 1,
          0,
          1
        ).getTime();
        delete params.compareYear;
      } else if (params.dateGroup === "month") {
        params.begDate = new Date(
          params.begDate.getFullYear(),
          params.begDate.getMonth(),
          1
        ).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear(),
          params.endDate.getMonth() + 1,
          1
        ).getTime();
      } else {
        params.begDate = new Date(
          params.begDate.getFullYear(),
          params.begDate.getMonth(),
          params.begDate.getDate()
        ).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear(),
          params.endDate.getMonth(),
          params.endDate.getDate() + 1
        ).getTime();
      }
      if (params.goods) params.skuId = params.goods.id;
      delete params.goods;
      return params;
    },
    loadEchart() {
      let params = Object.assign({}, this.makeParams(), {
        page: this.page - 1,
        size: this.size,
      });
      request({
        url: "@host:analysis;api/analy/purchase/trend",
        method: "get",
        params,
      })
        .then((res) => {
          this.storeChart = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.storeChart = [];
        });
    },
    draw() {
      if (this.storeChart && this.storeChart.length) {
        let series = {};
        let legData = [];
        let times = [];
        let seriesData = [];
        this.storeChart.forEach((d) => {
          if (d.year != 0 && d.month != 0 && d.day != 0) {
            let dt = d.year + "/" + d.month + "/" + d.day;
            times.push(dt);
          } else if (d.year != 0 && d.month != 0) {
            let dt = d.year + "/" + d.month;
            times.push(dt);
          } else {
            let dt = d.year;
            times.push(dt);
          }
        });

        this.options.fields.forEach((fn) => {
          let fns = fn.split("_");
          let fd = this.fields[fn];
          this.storeChart.forEach((d) => {
            if (!series.hasOwnProperty(fd.key)) {
              series[fd.key] = {
                name: fd.label,
                type: "line",
                yAxisIndex: fd.yIndex,
                smooth: true,
                data: [],
              };
              seriesData.push(series[fd.key]);
            }
            series[fd.key].data.push(
              Math.round(
                (d[fns[this.options.reportOnly ? 1 : 0]] || 0) * fd.ratio * 100
              ) / 100
            );

            if (this.options.yearOnYearShow) {
              let yk = "yoy_" + fd.key;
              if (!series.hasOwnProperty(yk)) {
                series[yk] = {
                  name: "同比" + fd.label,
                  type: "line",
                  yAxisIndex: fd.yIndex,
                  smooth: true,
                  data: [],
                };
                seriesData.push(series[yk]);
              }
              series[yk].data.push(
                Math.round(
                  (d.compareData
                    ? d.compareData[fns[this.options.reportOnly ? 1 : 0]] || 0
                    : 0) *
                    fd.ratio *
                    100
                ) / 100
              );
            }
          });
        });

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light", {
            height: 320,
          });
        }
        this.chart.clear();
        this.chart.setOption({
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: true,
          },
          grid: {
            left: "6%",
            right: "6%",
            bottom: "5%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: times,
          },
          yAxis: [
            {
              type: "value",
              name: "金额（万元）",
              splitNumber: 5,
            },
            {
              type: "value",
              name: "数量",
              splitNumber: 5,
            },
          ],
          series: seriesData,
        });
      }
    },
  },
};
</script>



